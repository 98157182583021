<header class="sombra-header">
  <div class="container-fluid">
    <div class="row justify-content-end align-items-center bg-gris-medio-1 color-blanco">
      <div class="col-4 col-md-2 text-center portal">
        <a href="https://www.ine.mx/" target="_Blank">Portal INE</a>
      </div>
      <div class="col-8 col-md-4 col-lg-3 col-xl-3">
        <div class="toggle-button-cover">
          <!-- <span class="text-button font14">See site in English</span> -->
          <div class="button-cover">
            <span *ngIf="!this.idiomaService.lang" class="text-button font14">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;English</span>
            <span *ngIf="this.idiomaService.lang" class="text-button font14">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Español</span>
                <div class="button r" id="custom-radio">
                  
                  <input type="checkbox" class="checkbox" (change)="cambiaIdioma()">
                  <div class="coverB">
                    <span></span>
                  </div>
                  <div class="layer"></div>
                </div>
              </div>
            </div>
      </div>
    </div>
  </div>
  <section *ngIf="!this.idiomaService.lang" class="menu">
    <div class="container">
      <div class="row">
        <div class="col-12">
          <nav class="navbar navbar-expand-lg navbar-light bg-light bg-gris-medio-3">
            <img class="logo-responsivo" src="../../../../assets/img/logo-VE.svg" alt="">

            <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarTogglerDemo03" aria-controls="navbarTogglerDemo03" aria-expanded="false" aria-label="Toggle navigation">
              <span class="navbar-toggler-icon"></span>
            </button>
            <div class="collapse navbar-collapse" id="navbarTogglerDemo03">
              <ul class="navbar-nav mr-auto mt-2 mt-lg-0">
                <li class="nav-item" [routerLinkActive]="['active']">
                  <a class="nav-link" routerLink="/inicio"><fa-icon class="icon" [icon]="faInicio"></fa-icon><span >Inicio</span></a>
                </li>
                <li class="nav-item dropdown" [routerLinkActive]="['active']">
                  <a class="nav-link dropdown-toggle" id="regimen" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                    <fa-icon class="icon" [icon]="faPergamino"></fa-icon><span >Régimen Electoral Mexicano</span>
                  </a>
                  <div class="dropdown-menu" aria-labelledby="regimen">
                    <a class="dropdown-item" [routerLinkActive]="['active']" routerLink="/regimen-electoral-mexicano/sistema-nacional-elecciones">Sistema Nacional de Elecciones</a>
                    <a class="dropdown-item" [routerLinkActive]="['active']" routerLink="/regimen-electoral-mexicano/participacion-actores">Participación y Actores Políticos</a>
                    <a class="dropdown-item" [routerLinkActive]="['active']" routerLink="/regimen-electoral-mexicano/igualdad-genero">Igualdad y Género</a>
                    <!-- <a class="dropdown-item" href="#">Voto de los mexicanos residentes en el extranjero</a> -->
                    <!-- <a class="dropdown-item" href="#">Glosario</a> -->
                    <!-- <a class="dropdown-item" href="#">Leyes y Reglamentos</a> -->
                  </div>
                </li>
                <!-- <li class="nav-item">
                  <a class="nav-link" href="#"><fa-icon class="icon" [icon]="faVoto"></fa-icon> <span >Jornada Electoral</span></a>
                </li> -->
                <li class="nav-item dropdown" [routerLinkActive]="['active']">
                  <a class="nav-link dropdown-toggle" id="prep" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                    <fa-icon class="icon" [icon]="faBar"></fa-icon><span class="icon-span">PREP</span>
                  </a>
                  <div class="dropdown-menu" aria-labelledby="prep">
                    <a class="dropdown-item" [routerLinkActive]="['active']" routerLink="/prep/resultados-preliminares">Resultados Preliminares</a>
                    <!-- <a class="dropdown-item" routerLink="/prep/participacion">Niveles de Desagregación de la Información</a> -->
                    <!-- <a class="dropdown-item" routerLink="/prep/igualdad">Imágenes de Actas</a> -->
                    <a class="dropdown-item" [routerLinkActive]="['active']" routerLink="/prep/acta">Acta PREP</a>
                  </div>
                </li>
                <li class="nav-item" [routerLinkActive]="['active']">
                  <a class="nav-link" routerLink="/computos/"><fa-icon class="icon" [icon]="faLap"></fa-icon><span >Cómputos</span></a>
                </li>
                <!-- <li class="nav-item">
                  <a class="nav-link" href="#"><fa-icon class="icon" [icon]="faBusqueda"></fa-icon><span class="icon-span">Estudios CAI</span></a>
                </li> -->
              </ul>
            </div>
          </nav>
        </div>
      </div>
    </div>
  </section> 

  <!-- English version -->
  <section *ngIf="this.idiomaService.lang"  class="menu">
  <div class="container">
    <div class="row">
      <div class="col-12">
        <nav class="navbar navbar-expand-lg navbar-light bg-light bg-gris-medio-3">
          <img class="logo-responsivo" src="../../../../assets/img/logo-VE.svg" alt="">
          <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarTogglerDemo03" aria-controls="navbarTogglerDemo03" aria-expanded="false" aria-label="Toggle navigation">
            <span class="navbar-toggler-icon"></span>
          </button>
          <div class="collapse navbar-collapse" id="navbarTogglerDemo03">
            <ul class="navbar-nav mr-auto mt-2 mt-lg-0">
              <li class="nav-item" [routerLinkActive]="['active']">
                <a class="nav-link" routerLink="/inicio"><fa-icon class="icon" [icon]="faInicio"></fa-icon><span >Home</span></a>
              </li>
              <li class="nav-item dropdown" [routerLinkActive]="['active']">
                <a class="nav-link dropdown-toggle" id="regimen" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                  <fa-icon class="icon" [icon]="faPergamino"></fa-icon><span >Mexican Electoral Regime</span>
                </a>
                <div class="dropdown-menu" aria-labelledby="regimen">
                  <a class="dropdown-item" [routerLinkActive]="['active']" routerLink="/regimen-electoral-mexicano/sistema-nacional-elecciones">National Electoral System</a>
                  <a class="dropdown-item" [routerLinkActive]="['active']" routerLink="/regimen-electoral-mexicano/participacion-actores">Participation and Political Actors</a>
                  <a class="dropdown-item" [routerLinkActive]="['active']" routerLink="/regimen-electoral-mexicano/igualdad-genero">Gender and Equality</a>
                  <!-- <a class="dropdown-item" href="#">Voto de los mexicanos residentes en el extranjero</a> -->
                  <!-- <a class="dropdown-item" href="#">Glosario</a> -->
                  <!-- <a class="dropdown-item" href="#">Leyes y Reglamentos</a> -->
                </div>
              </li>
              <!-- <li class="nav-item">
                <a class="nav-link" href="#"><fa-icon class="icon" [icon]="faVoto"></fa-icon> <span >Jornada Electoral</span></a>
              </li> -->
              <li class="nav-item dropdown" [routerLinkActive]="['active']">
                <a class="nav-link dropdown-toggle" id="prep" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                  <fa-icon class="icon" [icon]="faBar"></fa-icon><span class="icon-span">PREP</span>
                </a>
                <div class="dropdown-menu" aria-labelledby="prep">
                  <a class="dropdown-item" [routerLinkActive]="['active']" routerLink="/prep/resultados-preliminares">Preliminary Results</a>
                  <!-- <a class="dropdown-item" routerLink="/prep/participacion">Niveles de Desagregación de la Información</a> -->
                  <!-- <a class="dropdown-item" routerLink="/prep/igualdad">Imágenes de Actas</a> -->
                  <a class="dropdown-item" [routerLinkActive]="['active']" routerLink="/prep/acta">PREP Records</a>
                </div>
              </li>
              <li class="nav-item" [routerLinkActive]="['active']">
                <a class="nav-link" routerLink="/computos/"><fa-icon class="icon" [icon]="faLap"></fa-icon><span >Electoral Tallies</span></a>
              </li>
              <!-- <li class="nav-item">
                <a class="nav-link" href="#"><fa-icon class="icon" [icon]="faBusqueda"></fa-icon><span class="icon-span">Estudios CAI</span></a>
              </li> -->
            </ul>
          </div>
        </nav>
      </div>
    </div>
  </div>
  </section> 
</header>