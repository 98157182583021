import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { IdiomaService } from './services/idioma/idioma.service'

@NgModule({
  declarations: [],
  imports: [
    CommonModule
  ],
  providers:[
    IdiomaService
  ]
})
export class CoreModule { }
