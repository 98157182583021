import { Component, OnInit } from '@angular/core';

import {
        faQuestionCircle, faSearch, 
        faLaptop, faChartBar,
        faHome, faVoteYea, faScroll
      } from '@fortawesome/free-solid-svg-icons';

import { IdiomaService } from './../../../core/services/idioma/idioma.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  
  faPregunta = faQuestionCircle;
  faBusqueda = faSearch;
  faLap = faLaptop;
  faBar = faChartBar;
  faInicio = faHome;
  faVoto = faVoteYea;
  faPergamino = faScroll;

  constructor(public idiomaService:IdiomaService) { }

  ngOnInit(): void {
  }

  cambiaIdioma() {
    this.idiomaService.cambioIdioma()
 }
}
