<footer class="footer">
    <div class="container">
      <div class="row p-t-20 align-items-center">
        <div class="col-12 col-lg-4">
          <div class="row">
            <div class="col-12 col-lg-6">
              <img class="logoINE" src="../../../../assets/img/INE-blanco.svg" alt="Logo INE">
            </div>
            <div class="col-12 p-t-20">
              <b>Acerca de nosotros</b>
            </div>
            <div class="col-12">
                Año de creación 2020 v. 1.0
            </div>
            <div class="col-12">
                Derechos reservados, Instituto Nacional Electoral, México
            </div>
          </div>
          <hr class="d-lg-none">
        </div>
        <div class="col-12 col-lg-4">
          <div class="row p-t-20 p-b-20">
            <div class="col-12">
              Contacto:
            </div>
            <div class="col-12 p-t-20 font20">
                <a  href="https://www.facebook.com/INEMexico/" target="_blank"><fa-icon class="icon p-r-20 font34 color-blanco" [icon]="faFace"></fa-icon></a>
                <a  href="https://twitter.com/INEMexico" target="_blank"><fa-icon class="icon p-r-20 font34 color-blanco" [icon]="faTwitter"></fa-icon></a>
                <a  href="https://www.youtube.com/user/IFETV" target="_blank"><fa-icon class="icon font34 color-blanco" [icon]="faYoutube"></fa-icon></a>
            </div>
            <div class="col-12 p-t-10">
              <p class="m-b-0 font14">Visitante Extranjeros:</p>
              <p class="font12">visitantes.extranjeros@ine.mx</p>
            </div>
          </div>
        </div>
        <div class="col-12 col-lg-4">
          <div class="row p-b-20">
            <div class="col-12">
              <p class="m-b-0 font14">Horario de atención</p>
              <p class="m-b-0 p-t-20 font12">Lunes a viernes: 9:00 a 19:00 hrs.</p>
              <p class="m-b-0 font12">Sábados: 9:00 a 16:00 hrs.</p>
              <p class="m-b-0 font12">Teléfono: 55 5483 8110</p>
              <p class="m-b-0 font12">IP: 348110</p>
            </div>
          </div>
        </div>
      </div>
    </div>
</footer>