import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class IdiomaService {
  public lang = false;
  constructor() { }

  cambioIdioma(){
    this.lang = !this.lang
  }
  
}
