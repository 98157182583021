import { NgModule } from '@angular/core';
import { Routes, RouterModule, PreloadAllModules } from '@angular/router';

import { LayoutComponent } from "./layout/layout.component";

const routes: Routes = [
  {
    path:'',
    component: LayoutComponent,
    children:[
      {
        path: '',
        redirectTo: '/inicio',
        pathMatch: 'full'
      },{
        path : 'inicio',
        loadChildren: () => import('./home/home.module').then( m => m.HomeModule)
      },{
        path: 'regimen-electoral-mexicano',
        loadChildren: () => import('./informacion-general/informacion-general.module').then( m => m.InformacionGeneralModule)
      },{
        path: 'jornada',
        loadChildren: () => import('./jornada-electoral/jornada-electoral.module').then( m => m.JornadaElectoralModule)
      },{
        path: 'prep',
        loadChildren: () => import('./prep/prep.module').then( m => m.PrepModule)
      },{
        path: 'computos',
        loadChildren: () => import('./computos/computos.module').then( m => m.ComputosModule)
      },{
        path: 'cai',
        loadChildren: () => import('./cai/cai.module').then( m => m.CaiModule)
      }
    ]
  },{
    path:'**',
    loadChildren: () => import ('./not-found/not-found.module').then( m => m.NotFoundModule)
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes,{
    preloadingStrategy: PreloadAllModules
  })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
